import React, { useCallback, useEffect, useContext, useState } from 'react';
import notify from 'notify';
import useAuthContext from 'hooks/useAuthContext';
import {
    getSignaturesList,
    sendSignatureReminder
} from './api';
import styled from '@emotion/styled';
import MainLayout from 'components/MainLayout';
import { WorkspaceContext } from 'workspace';

const SignaturesPage = () => {
    const { openWorkspace } = useContext(WorkspaceContext);

    const [signatures, setSignatures] = useState<any>(null);

    const fetchSignatures = useCallback(async () => {
        try {
            const signs = await getSignaturesList();

           // console.log(signs);
            setSignatures(signs);
        } catch (err) {
            notify(err.message);
        }
    }, [getSignaturesList]);

    const sendSignatureReminderFunction = (memberId,documentId) => {
      if(authData?.superAdmin){
        sendSignatureReminder(memberId,documentId);
        notify('Signature reminder sent.');
      }else{
        notify('This functionality is disabled in view only mode.');
      }
        
    }

    const { authData } = useAuthContext();

    useEffect(() => {
        fetchSignatures();
    }, [fetchSignatures]);
    
    if (!signatures || signatures.length === 0) return (
        <MainLayout><StyledDashboardPage className="documents-page"><p className="documents-page__empty">No open signatures.</p></StyledDashboardPage></MainLayout>
    );
    return (
        <MainLayout>
            <StyledDashboardPage className="documents-page">
                <div className="signatures__header">
                <h4 className="signatures__title">Pending signature requests</h4>
                
                </div>
            
                <div className="documents-container">
                    
                    <table className="signatures-list">
                        <thead>
                            <tr>
                            <th>Property Address</th>
                            <th>Document</th>
                            
                            <th>Signer</th>
                            <th>Role</th>
                            <th>Date Created</th>
                            <th>Signed</th>
                            <th></th>
                            {/*  eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                            <th />
                            </tr>
                        </thead>
                        <tbody>
                            {signatures.map(node => (
                            <tr>
                                <td onClick={() => openWorkspace(node.listing_id)} className='listedPropAddress'>{node.propaddress}</td>
                                <td>{node.docname}</td>
                                
                                <td>{node.name}</td>
                                
                                <td>{node.role_specific.match(/[A-Z][a-z]+/g).join(" ")}</td>
                                <td>{node.date_created}</td>
                                <td onClick={() => openWorkspace(node.listing_id)} className='listedPropAddress'>{node.has_signed+"/"+node.total_required}</td>
                                <td onClick={e => sendSignatureReminderFunction(node.people_id,node.document_id)} className="ttNotSigned">Send signature reminder</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
        </StyledDashboardPage>
      </MainLayout>
    )

}

export default SignaturesPage;


const StyledDashboardPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .listedPropAddress{cursor:pointer;}

  .page_explanation{    text-align: left;
    width: 100%;
    margin: 0;}

  .ttNotSigned{color: #df1721;cursor:pointer;}

  .signatures__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    margin: 16px 0;

    h4 {
      margin: 0;
    }
  }

  .documents-page {
    &__header {
      display: flex;
      justify-content: space-between;
      margin: 0 0 28px;
      width: 100%;
    }
    &__empty {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      color: ${props => props.theme.colors.grayDark};
    }
    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: black;
      margin: 0;
      outline: none;

      &.breadcrumb {
        cursor: pointer;
        &:hover,
        &:active,
        &:focus {
          opacity: 0.7;
        }
      }
    }

    &__breadcrumbs {
      display: flex;
      height: 32px;
      .breadcrumb {
        display: flex;
        font-weight: normal;
        font-size: 16px;
        line-height: 32px;
        white-space: nowrap;
        align-items: center;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: none;
        &:hover,
        &:active {
          opacity: 0.7;
        }
        .icon {
          flex-shrink: 0;
          transform: rotate(270deg);
          width: 24px;
          height: 24px;
        }
      }
    }

    &__mobile-folder {
      display: flex;
      align-items: center;

      h5 {
        margin: 0;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        white-space: nowrap;
      }

      .button {
        background: ${props => props.theme.colors.seashell};
        margin-right: 12px;

        .icon {
          vertical-align: middle;
          fill: black;
        }
      }
    }
    &__controls {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;

      & > .button,
      & > .input {
        margin-left: 16px;
        height: 32px;
      }

      .input-component {
        height: 32px !important;
      }

      .button .icon {
        vertical-align: middle;
      }
    }

    &__search {
      width: 100%;
      max-width: 300px;
    }

    &__upload_btn {
      width: 90px;
    }
    &__empty {
      padding: 20px;
      text-align: center;
    }
  }

  .search {
    .input-wrapper {
      .input-component {
        height: 32px;
      }
    }
  }

  .documents-container {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 300px;
    margin-top: 32px;
  }

  .signatures-list {
    width: 100%;
    thead th {
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      text-align: left;
      color: ${props => props.theme.colors.grayDark};
      padding-bottom: 9px;
      border-bottom: 1px solid ${props => props.theme.colors.seashell};
    }
    &:not(:last-of-type) td {
        border-bottom: 1px solid ${props => props.theme.colors.seashell};
    }
    td {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        height: 32px;
    }
  }

  .signatures-list-mobile {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .documents-page {
      &__header {
        margin-bottom: 0;
      }

      &__controls {
        .button {
          width: auto;
          margin-left: 8px !important;
        }
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .documents-page {
      &__folder_btn {
        margin-left: 8px;
      }
    }
  }
`;