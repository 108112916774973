/* eslint-disable camelcase */
import { fetchWithToken } from 'utils/fetch';
import { urlEncodeBody } from 'utils/requests';

export async function getSignaturesList(): Promise<void> {
    const response = await fetchWithToken(`/documents/signatures/open`);
    const data = await response.json();
  
    return data;
}

export async function sendSignatureReminder(
    member_id: number,
    document_id: number
): Promise<void> {
const response = await fetchWithToken(`/documents/pdf_editor/`+document_id+`/signature_reminder`, {
    method: 'POST',
    body: urlEncodeBody({
    member_id: member_id,
    })
});
}